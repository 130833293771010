import { UpdateUserData, User } from '../../models/user';
import { firestore } from '../index';

const USERS = 'users';

export const createUser = async (user: User) => {
    firestore
        .collection(USERS)
        .doc(user.uid)
        .set(user);
}

export const updateUser = (userUpdateData: UpdateUserData): Promise<User> => {
    return userNameIsUnique(userUpdateData.username, userUpdateData.uid)
        .then((isUnique) => {
            if (!isUnique) {
                throw new Error("This username isn't available. Please try another")
            }
        })
        .then(() => {
            const updateUserPromise = new Promise<User>((resolve, reject) => {
                const docRef = firestore
                    .collection(USERS)
                    .doc(userUpdateData.uid)

                docRef.onSnapshot({
                    next: (val) => {
                        if (!val.exists) {
                            reject();
                            return
                        }
                        resolve(val.data() as User)
                    },
                    error: (error) => {
                        reject(error);
                    }
                })
                docRef.set(userUpdateData, { merge: true });
            })
            return updateUserPromise
        })
}

export const userNameIsUnique = (userName: string | null, uid: string): Promise<boolean> => {
    return firestore
        .collection(USERS)
        .where('username', '==', userName)
        .where('uid', '!=', uid)
        .get()
        .then((data) => data.empty)
}

export const getUser = async (uid: string): Promise<User | null> => {
    const ref = await firestore
        .collection(USERS)
        .doc(uid)
        .get()

    if (!ref.exists) {
        return null;
    }

    return ref.data() as User;
}

export const updateUsersImageURL = async (uid: string, imageURL: string) => {
    firestore
        .collection(USERS)
        .doc(uid)
        .update({ photoUrl: imageURL })
}