import React, { useEffect, useState } from 'react'
import { ButtonComponent } from '../../atoms/button'
import { ImageComponent } from '../../atoms/image'
import './edit-modal.scss'

interface Props {
    onClose: () => void;
    inputProps: InputProps;
}

export interface InputProps {
    type: InputType
    currentValue: string | null,
    onEditCompleted: (text: string) => Promise<void>,
    maxLenght: number | undefined
}

type InputType =
    | 'name'
    | 'userName'
    | 'bio'
    | 'email'
    | 'phone'

export const EditModal = ({ onClose, inputProps }: Props) => {

    const onClickDone = () => {
        inputProps.onEditCompleted(text)
            .then(() => onClose())
            .catch((e) => setErrorText(e.message))
    }

    const [text, setText] = useState(inputProps.currentValue ?? '');
    const [errorText, setErrorText] = useState<string | null>(null);

    const header = () => {
        switch (inputProps.type) {
            case 'bio': return 'Bio'
            case 'email': return 'Email'
            case 'name': return 'Name'
            case 'phone': return 'Phone'
            case 'userName': return 'Username'
        }
    }

    const description = () => {
        switch (inputProps.type) {
            case 'name': return 'Use your real name to make it easier to connect to people you know'
            default: return null;
        }
    }

    const className = () => {
        let result = 'modal-input'
        if (inputProps.type === 'bio') {
            result = result + ' bio';
        }
        return result
    }

    const autoGrow = () => {
        const element = document.getElementById('input')!;
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
    }

    const [editChanged, setEditChanged] = useState(false);

    const onChangeText = (el: any) => {
        const newText: string = el.target.value;
        setText(newText);
        setErrorText(null);
        setEditChanged(newText !== inputProps.currentValue);
    }

    useEffect(() => {
        autoGrow();
    }, [])

    return (
        <div>
            <div className='modal-overlay'></div>
            <div className='edit-modal'>
                <div className='header margin-header'>
                    <ButtonComponent className='edit-back Button' onPress={onClose}>
                        <ImageComponent src='/new-assets/images/back.svg' />
                    </ButtonComponent>
                    <div className='header-title'>{header()}</div>
                    <button className='done Button' onClick={onClickDone} hidden={!editChanged}>
                        Done
                    </button>
                </div>
                <div className='edit-modal-body'>
                    <textarea
                        placeholder={inputProps.type === 'bio' ? 'Share something about yourself' : undefined}
                        autoFocus={true}
                        onChange={onChangeText}
                        onInput={autoGrow}
                        id='input'
                        className={className()}
                        inputMode={inputProps.type === 'phone' ? 'tel' : 'text'}
                        maxLength={inputProps.maxLenght}
                    >{text}
                    </textarea>
                    {description() === null ? <div /> : <span className='tip'>{description()}</span>}
                    {errorText === null ? <div /> : <span className='error-text'>{errorText}</span>}
                </div>
            </div>
        </div>
    )
}