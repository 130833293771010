import React, { useState } from 'react';
import { AuthBackground } from '../../atoms/auth-background';
import { ButtonComponent } from '../../atoms/button';
import { ImageComponent } from '../../atoms/image';
import { ProfileButton } from '../../atoms/profile-button';
import { Footer } from '../footer';
import { Posts } from '../posts';
import { useMediaContext } from '../../../context/media-query-context';
import { FavouriteItem } from '../../../models/favourite';
import { WishlistItem } from '../../../models/wishlist';
import { MenuSwitchMode } from '../../../pages/profile/profile';
import './profile-component.scss';
import { useHistory } from 'react-router';

interface Props {
    onPressShareButton: () => void;
    onPressEditUser: () => void;
    onPressClout: () => void;
    onPressFollower: () => void;
    onPressFollowing: () => void;
    setSwitchMode: (mode: MenuSwitchMode) => void;
    onPickedImage: (e: any) => void;
    userName: string | undefined;
    userImage: string;
    clout: number;
    numberOfFollowers: number;
    numberOfFollowings: number;
    bio: string | undefined;
    favourites: FavouriteItem[];
    menuSwitchMode: MenuSwitchMode;
    wishList: WishlistItem[];
}

type FavouriteSwitchMode = 'table' | 'solo'

export const ProfileMobile = ({
    userName,
    onPressShareButton,
    userImage,
    onPressEditUser,
    clout,
    numberOfFollowers,
    numberOfFollowings,
    bio,
    favourites,
    menuSwitchMode,
    wishList,
    onPickedImage,
    setSwitchMode,
    onPressClout,
    onPressFollower,
    onPressFollowing }: Props
) => {
    const [favSwitchMode, setFavSwitchMode] = useState<FavouriteSwitchMode>('table')
    const [showFullBio, setShowFullBio] = useState(false)
    const history = useHistory();

    const isMobile = useMediaContext();

    const renderFavouriteContent = () => {
        if (favourites.length === 0) {
            return (
                <div className='no-posts-conteiner'>
                    <ButtonComponent onPress={() => { history.push('/add-to-favorite') }}>
                        <p className='plus'><ImageComponent className='plus-image' src='/new-assets/images/favorite-empty-plus.svg' /> <span className='plus-text'>Add Favorite</span></p>
                    </ButtonComponent>
                    <p className='why-title'>Why share Favorites?</p>
                    <p className='why-text'><span className='why-strong'>You</span> get free products. <span className='why-strong'>Your friends</span> get <br />discounts. <span className='why-strong'>Your favorite brands</span> get noticed</p>
                </div>
            )
        }
        return favSwitchMode === 'solo' ?
            <div className=''>
                {favourites.map((el) => <ButtonComponent className='post-table-view' onPress={() => { }}><ImageComponent src={el.imageURL} /></ButtonComponent>)}
            </div> :
            favourites.map((el) => <Posts userPhotoUrl={userImage} favouriteItem={el} key={el.id} />)
    }

    const renderWishlistContent = () => {
        return (
            <div className='wishlist-text no-posts-conteiner'>
                <ImageComponent src='/new-assets/images/wishlist-big.svg' /><br /><br />Products you add to wishlist<br /> will appear here
            </div>
        )
    }

    if (isMobile) {
        return (
            <div className='profile-container'>
                <div className='profile-header'>
                    {userName}
                    <ButtonComponent
                        onPress={onPressShareButton}
                        className='right buttonImage'>
                        <ImageComponent src='/new-assets/images/share.svg' />
                    </ButtonComponent>
                </div>
                <div className='div-with-background'>
                    <div className='image-div'>
                        <ImageComponent onClick={() => document.getElementById('image-input')?.click()} src={userImage || '/new-assets/images/no-user-image.svg'} className='profile-image' alt=''/>
                        <input type="file" id={'image-input'} className='file-input' accept='image/*,.HEIC' onChange={onPickedImage} />
                    </div>
                    <p className='user-name'>{userName}</p>
                    <ButtonComponent className='edit-profile-button' onPress={onPressEditUser}>Edit Profile</ButtonComponent>
                    {
                        bio?.length != null && bio?.length >= 125 ?
                            showFullBio ?
                                <p className='user-bio' onClick={() => { setShowFullBio(false) }}>{bio}</p> :
                                <p className='user-bio'>{bio?.slice(0, 115)}<ButtonComponent onPress={() => { setShowFullBio(true) }} className='more-button'>...more</ButtonComponent></p> :
                            <p className='user-bio'>{bio}</p>
                    }
                    <div className='profile-buttons-grid'>
                        <ProfileButton onPress={onPressClout} value={clout} title='clout' className='profile-button' isValueBlue={true} />
                        <ProfileButton onPress={onPressFollower} value={numberOfFollowers} title='followers' className='profile-button' />
                        <ProfileButton onPress={onPressFollowing} value={numberOfFollowings} title='following' className='profile-button' />
                    </div>
                </div>
                <div className='profile-posts-control-container'>
                    <div className='switch-container'>
                        <ButtonComponent onPress={() => { setSwitchMode('favourite') }} className={menuSwitchMode === 'favourite' ? 'switch-button active' : 'switch-button'} >
                            <ImageComponent className='switch-image' src='/new-assets/images/star-favorite.svg' />
                            Favorites <strong className='fav-value'>{favourites.length}</strong>
                            <ButtonComponent onPress={() => { setFavSwitchMode(favSwitchMode === 'table' ? 'solo' : 'table') }} className='dot'>
                                {favSwitchMode !== 'table' ?
                                    <ImageComponent src='/new-assets/images/table-view.svg' /> :
                                    <ImageComponent src='/new-assets/images/solo-view.svg' />}
                            </ButtonComponent>
                        </ButtonComponent>
                        <ButtonComponent onPress={() => { setSwitchMode('wishlist') }} className={menuSwitchMode === 'wishlist' ? 'switch-button active' : 'switch-button'}>
                            <ImageComponent className='switch-image wishlist' src='/new-assets/images/wishlist.svg' />
                            Wishlist <strong className='fav-value'>{wishList.length}</strong>
                        </ButtonComponent>
                    </div>
                    {menuSwitchMode === 'favourite' ? renderFavouriteContent() : renderWishlistContent()}
                </div>
                <Footer photoUrl={userImage} />
            </div>
        )
    } else {
        return (
            <AuthBackground>
                <div className='header'>
                    <ImageComponent className='main-logo' src='/new-assets/images/main-logo.svg' />
                    <Footer photoUrl={userImage} className='nav' />
                </div>
                <div className='profile-container'>
                    <ButtonComponent onPress={onPressEditUser} className='left buttonImage'><ImageComponent src='/new-assets/images/details.svg' /></ButtonComponent>
                    <ButtonComponent onPress={onPressShareButton} className='right buttonImage'><ImageComponent src='/new-assets/images/share.svg' /></ButtonComponent>
                    <div className='profile-grid'>
                        <div className='image-div'>
                            <ImageComponent onClick={() => document.getElementById('image-input')?.click()} src={userImage} className='profile-image' alt='' width='64' />
                            <input type="file" id='image-input' className='file-input' accept='image/*,.HEIC' onChange={onPickedImage} />
                        </div>
                        <div className='user-info'>
                            <p className='user-name'>{userName}</p>
                            <div>
                                <ProfileButton onPress={onPressClout} value={clout} title='clout' className='profile-button' isValueBlue={true} isDesktop={true} />
                                <ProfileButton onPress={onPressFollower} value={numberOfFollowers} title='followers' className='profile-button' isDesktop={true} />
                                <ProfileButton onPress={onPressFollowing} value={numberOfFollowings} title='following' className='profile-button' isDesktop={true} />
                            </div>
                            <p className='user-bio'>{bio}</p>
                        </div>
                    </div>
                    <div className='profile-posts-control-container'>
                        <div className='switch-container'>
                            <ButtonComponent onPress={() => { setSwitchMode('favourite') }} className={menuSwitchMode === 'favourite' ? 'switch-button active' : 'switch-button'} >
                                <ImageComponent className='switch-image' src='/new-assets/images/star-favorite.svg' />
                                Favorites <strong className='fav-value'>{favourites.length}</strong> <ButtonComponent onPress={() => { }} className='dot'>&#8226;</ButtonComponent>
                            </ButtonComponent>
                            <ButtonComponent onPress={() => { setSwitchMode('wishlist') }} className={menuSwitchMode === 'wishlist' ? 'switch-button active' : 'switch-button'}>
                                <ImageComponent className='switch-image' src='/new-assets/images/wishlist.svg' />
                                Wishlist <strong className='fav-value'>{wishList.length}</strong>
                            </ButtonComponent>
                        </div>
                        <div className='no-posts-conteiner'>
                            {menuSwitchMode === 'favourite' ?
                                <div>
                                    <p className='plus'><ImageComponent className='plus-image' src='/new-assets/images/favorite-empty-plus.svg' /> <span className='plus-text'>Add Favorite</span></p>
                                    <p className='why-title'>Why share Favorites?</p>
                                    <p className='why-text'>
                                        <span className='why-strong'>You</span> get free products. <span className='why-strong'>Your friends</span> get <br />
                                    discounts. <span className='why-strong'>Your favorite brands</span> get noticed
                                </p>
                                </div> :
                                <div className='wishlist-text'>
                                    <ImageComponent src='/new-assets/images/wishlist-big.svg' /><br /><br />
                                Products you add to wishlist<br />
                                will appear here
                            </div>}
                        </div>
                    </div>
                </div>

                {/* <div className='profile-container'>
                    <ButtonComponent onPress={onPressLeft} className='left buttonImage'><ImageComponent src='/new-assets/images/details.svg'/></ButtonComponent>
                    <ButtonComponent onPress={onPressRight} className='right buttonImage'><ImageComponent src='/new-assets/images/share.svg'/></ButtonComponent>
                    <ImageComponent src='/new-assets/images/no-user-image.svg' className='profile-image'/>
                    <p className='user-name'>User Name</p>
                    <p className='user-bio'>User Bio</p>
                    <div>
                        <ProfileButton onPress={press} value='10' title='clout' className='profile-button' isValueBlue={true}/>
                        <ProfileButton onPress={press} value='1' title='followers' className='profile-button'/>
                        <ProfileButton onPress={press} value='0' title='following' className='profile-button'/>
                    </div>
                    <div className='profile-posts-control-container'>
                        <div  className='switch-container'>
                            <ButtonComponent onPress={()=>{if(menuSwitch!==1) setMenuSwitch(1)}} className={menuSwitch === 1 ? 'switch-button active' :'switch-button'} >
                                <ImageComponent className='switch-image' src='/new-assets/images/star-favorite.svg'/>
                                Favorites <strong className='fav-value'>0</strong> <ButtonComponent onPress={()=>{}} className='dot'>&#8226;</ButtonComponent>
                            </ButtonComponent>
                            <ButtonComponent onPress={()=>{if(menuSwitch!==2) setMenuSwitch(2)}} className={menuSwitch === 2 ? 'switch-button active' :'switch-button'}>
                                <ImageComponent className='switch-image' src='/new-assets/images/wishlist.svg'/>
                                Wishlist <strong className='fav-value'>0</strong>
                            </ButtonComponent>
                        </div>
                        <div className='no-posts-conteiner'>
                            { menuSwitch === 1 ? 
                            <div>
                                <p className='plus'><ImageComponent className='plus-image' src='/new-assets/images/favorite-empty-plus.svg'/> <strong className='plus-text'>Add Favorite</strong></p>
                                <p className='why-title'>Why share Favorites?</p>
                                <p className='why-text'>
                                    <strong className='why-strong'>You</strong> get free products. <strong className='why-strong'>Your friends</strong> get <br/>
                                    discounts. <strong className='why-strong'>Your favorite brands</strong> get noticed
                                </p>
                            </div> : 
                            <div className='wishlist-text'>
                                <ImageComponent src='/new-assets/images/wishlist-big.svg'/><br/><br/>
                                Products you add to wishlist<br/>
                                will appear here
                            </div>}
    
                            
                        </div>
                    </div>
                </div> */}
            </AuthBackground>
        )
    }
}