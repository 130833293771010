// import firebase from 'firebase'
import { Brand } from '../../models/brand';
import { Product } from '../../models/product';
import { firestore } from '../index'

const BRAND = 'brand';
const PRODUCT = 'products';
const SUGGEST_IMAGES = 'suggest-images';

export const searchBrand = async (text: string): Promise<Brand[]> => {
    const ref = await firestore
        .collection(BRAND)
        .where('name', '>=', text)
        .withConverter(brandConverter)
        .get();
    if (ref.empty) {
        return [];
    }
    const brands = ref.docs.map((e) => e.data())
    return brands;
}

export const searchProduct = async (text: string): Promise<Product[]> => {
    const ref = await firestore
        .collection(PRODUCT)
        .where('name', '>=', text)
        .withConverter(productConverter)
        .get()
    if (ref.empty) {
        return []
    }
    const products = ref.docs.map((e) => e.data())
    return products;
}


export const getSuggestImages = async (productID: string): Promise<string[]> => {
    const ref = await firestore
        .collection(PRODUCT)
        .doc(productID)
        .collection(SUGGEST_IMAGES)
        .get()
    if (ref.empty) {
        return []
    }
    const initialVal: string[] = [];
    const data = ref.docs.reduce((accumulator, currentVal) => {
        const data: string[] = currentVal.data().images;
        return accumulator.concat(data);
    }, initialVal);
    return data;
}

export const getSuggestedProducts = async () => {
    const ref = await firestore
        .collection(PRODUCT)
        .withConverter(productConverter)
        .orderBy('name', 'desc') //TODO: Add algorithm suggest product to firebase cloud function then
        .limit(1)
        .get();

    if (ref.empty) {
        return []
    }
    return ref.docs.map((e) => e.data())
}

export const getTrendingProducts = async (): Promise<Product[]> => {
    const ref = await firestore
        .collection(PRODUCT)
        .withConverter(productConverter)
        .orderBy('name', 'asc') //TODO: Add algorithm trending product to firebase cloud function then
        .limit(1)
        .get()

    if (ref.empty) {
        return []
    }

    return ref.docs.map((e) => e.data())
}

const productConverter = {
    toFirestore: (product: Product) => {
        return {
            id: product.id,
            avatar: product.avatar,
            name: product.name,
            brand: product.brand,
        }
    },
    fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options)
        const product: Product = {
            id: data.id,
            name: data.name,
            avatar: data.avatar,
            brand: data.brand,
        }
        return product
    }
}

const brandConverter = {
    toFirestore: (brand: Brand) => {
        return {
            id: brand.id,
            name: brand.name,
            avatar: brand.avatar,
        }
    },
    fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options);
        const brand: Brand = {
            id: data.id,
            avatar: data.avatar,
            name: data.name,
        }
        return brand
    }
}