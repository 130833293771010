import { Tag } from '../../models/tag';
import { firestore } from '../index'

const TAGS = 'tags';

export const getProductsTags = async (productID: string): Promise<Tag[]> => {
    const ref = await firestore
        .collection(TAGS)
        .where('productID', '==', productID)
        .withConverter(tagConverter)
        .get()

    if (ref.empty) {
        return [];
    }

    const tags = ref.docs.map((e) => e.data())
    return tags;
}

export const getAllTags = async() => {
    const ref = await firestore
        .collection(TAGS)
        .withConverter(tagConverter)
        .get()

    if (ref.empty) {
        return [];
    }

    return ref.docs.map((e) => e.data())
}

const tagConverter = {
    toFirestore: (tag: Tag) => {
        return {
            id: tag.id,
            name: tag.name,
            productID: tag.productID
        }
    },
    fromFirestore: (snapshot: any, options: any) => {
        const data = snapshot.data(options)
        const tag: Tag = {
            id: data.id,
            name: data.name,
            productID: data.productID,
        }
        return tag;
    }
}