import firebase from 'firebase'
import { firestore } from '../index'
import { FavouriteItem } from '../../models/favourite';
import { Product } from '../../models/product';

const FAVOURITE = 'favourites';

export const getUsersFavourite = async (userId: string): Promise<FavouriteItem[]> => {
    const refs = await firestore
        .collection(FAVOURITE)
        .where('userId', '==', userId)
        .get()
    if (refs.empty) {
        return [];
    }
    const result = refs.docs.map((ref): FavouriteItem => {
        const data = ref.data();
        return {
            id: data.id,
            brand: data.brand,
            productId: data.productId,
            imageURL: data.imageURL,
            description: data.description,
            tags: data.tags,
            userId: data.userId,
            numberOfComments: data.numberOfComments ?? 0,
            numberOfLikes: data.numberOfLikes ?? 0,
            lastComment: data.lastComment ?? null,
            productName: data.productName,
            lastCommentAuthor: data.lastCommentAuthor ?? null,
            createdAt: data.createdAt.toDate(),
            updatedAt: data.updatedAt.toDate(),
        }
    })
    return result;
}

export const addProductToFavourite = async (props: AddProductProps) => {
    const docRef = firestore
        .collection(FAVOURITE)
        .doc();
    docRef.set({
        id: docRef.id,
        brand: props.product.brand,
        productId: props.product.id,
        userId: props.userId,
        imageURL: props.imageURL,
        description: props.description,
        tags: props.tags,
        productName: props.product.name,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
    })
}

interface AddProductProps {
    userId: string,
    product: Product,
    imageURL: string, 
    description: string,
    tags: string[],
}