import firebase from 'firebase';
export interface User {
  uid: string; // auth.currentUser.uid
  email: string | undefined;
  displayName: string | undefined;
  username: string | undefined;
  bio: string | undefined;
  phone: string | undefined;
  photoUrl: string | undefined; // auth.currentUser.photoURL
  clout: number | undefined; // 0
  follower: number | undefined, //TODO: Change to number
  following: number | undefined,
}

export interface UsersShortInfo {
  avatar: string;
  name: string;
  id: string;
}
export interface UpdateUserData {
  photoUrl: string | null;
  uid: string;
  displayName: string | null;
  username: string | null;
  bio: string | null;
  email: string | null;
}


export const userConverter = {
  toUserInfo(user: User): UpdateUserData {
    return {
      photoUrl: user!.photoUrl ?? null,
      uid: user!.uid,
      displayName: user!.displayName ?? null,
      username: user!.username ?? null,
      bio: user!.bio ?? null,
      email: user!.email ??  null,
    }
  },
  fromFireBaseUser(user: firebase.User): User {
    return {
      photoUrl: undefined,
      uid: user.uid,
      displayName: user.displayName ?? undefined,
      username: undefined,
      email: user.email ?? undefined,
      bio: undefined,
      phone: user.phoneNumber ?? undefined,
      clout: 0,
      follower: 0,
      following: 0,
    }
  }
}

export const userIsLoggedIn = (user: User) => {
  return user.email !== undefined && user.displayName !== undefined && user.username !== undefined;
}