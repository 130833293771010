import * as firebase from 'firebase/app';

import { auth } from './index';

export const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('email');
  provider.addScope('profile');
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile');

  const credential = await auth.signInWithPopup(provider);

  return credential.user;
};

export const signInWithFacebook = async () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope('email');

  const credential = await auth.signInWithPopup(provider);

  return credential.user;
};

export const signInWithApple = async () => {
  var provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');

  const credential = await auth.signInWithPopup(provider);

  return credential.user;
};
export const signUp = async (email: string, password: string) => {
  await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  const credential = await auth.createUserWithEmailAndPassword(email, password);

  return credential.user;
};

export const signIn = async (email: string, password: string) => {
  await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  const credential = await auth.signInWithEmailAndPassword(email, password);

  return credential.user;
};

export const signOut = () => {
  return auth.signOut();
};

export const updateUserProfile = async (displayName: string, photoURL: string) => {
  if (!auth.currentUser) {
    return;
  }

  await auth.currentUser.updateProfile({
    displayName,
    photoURL
  });
}